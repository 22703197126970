import type { MetaFunction } from "@remix-run/node";
import { APP_DESCRIPTION, APP_TITLE } from "./constants";

type MetaFunctionProps = {
  title: string;
  description?: string;
};
export const defaultMeta = ({
  title,
  description,
}: MetaFunctionProps): ReturnType<MetaFunction> => [
  { title: `${title} | ${APP_TITLE}` },
  { name: "description", content: description ?? APP_DESCRIPTION },
  { name: "og:title", content: `${title} | ${APP_TITLE}` },
  { name: "og:site_name", content: "Forge a Brand That Lasts" },
  { name: "og:description", content: description ?? APP_DESCRIPTION },
  { name: "twitter:site", content: "@paletly" },
  { name: "twitter:title", content: `${title} | ${APP_TITLE}` },
  { name: "twitter:description", content: description ?? APP_DESCRIPTION },
  { name: "twitter:card", content: "summary_large_image" },
  { name: "twitter:image", content: "https://paletly.link/images/paletly.png" },
  { name: "og:image", content: "https://paletly.link/images/paletly.png" },
];
